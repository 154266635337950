import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import throttle from 'lodash/throttle';
import * as React from 'react';

import { GOOGLE_MAP_API_KEY } from 'src/constants';

import { appEnvControl, formatAddress, parsePlaces } from 'utils';

import { geocodeByAddress, getLatLng } from './utils';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type Props = Omit<FieldBaseProps, 'onChange'> & {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (arg0: { name: string; value: LocationType | null }) => void;
  onChangePlainAddress?: (arg0: { name: string; value: string }) => void;
  value?: LocationType;
  errors: Array<string>;
  type?: string;
  label?: string;
  fullWidth?: boolean;
  searchOptions: AnyObject;
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  valueAsString?: boolean;
};

export const GooglePlacesAutocomplete: React.FC<Props> = ({
  view = {},
  value,
  name,
  label,
  errors = [],
  onChange,
  additional,
  onChangePlainAddress,
  valueAsString,
}) => {
  const formattedValue: string = valueAsString ? value : formatAddress(value);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly string[]>(value ? [formattedValue] : []);
  const loaded = React.useRef(false);

  const helperText = errors.join(' ');
  const withError = errors.length > 0;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      const key = GOOGLE_MAP_API_KEY[appEnvControl.currentEnvWithDefaultDev()];
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!inputValue) {
      setOptions(value ? [formattedValue] : []);
      return undefined;
    }
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly string[] = [];

        if (results) {
          newOptions = [...newOptions, ...results.map((item) => item.description)];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (value: LocationType | null) => {
    if (!!onChange && !onChangePlainAddress) {
      onChange({ name, value });
      if (additional?.liveUpdate !== undefined) {
        additional.liveUpdate(name);
      }
    }

    if (!onChange && !!onChangePlainAddress) {
      const formattedValue = formatAddress(value);
      onChangePlainAddress({ name, value: formattedValue });
    }
  };

  const handleSelect = async (address: string) => {
    try {
      setInputValue('');
      const results = await geocodeByAddress(address);

      if (!results.length) {
        return;
      }
      const result = results[0];

      const { lat, lng } = await getLatLng(results[0]);

      const processData = parsePlaces(result.address_components);
      if (!lat || !lng || !processData) {
        setInputValue('');
        return;
      }
      setInputValue(
        formatAddress({
          lat: lat?.toString(),
          lng: lng?.toString(),
          ...processData,
        }),
      );
      handleChange({
        lat: lat?.toString(),
        lng: lng?.toString(),
        ...processData,
      });
    } catch (e) {
      console.error('Error', e);
    }
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={options}
      autoComplete
      freeSolo
      value={formattedValue ? formattedValue : null}
      onChange={(event: React.SyntheticEvent, newValue: string | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        newValue ? handleSelect(newValue) : handleChange(null);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} error={withError} helperText={helperText} label={label} fullWidth={view.fullWidth} />}
    />
  );
};
