import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { get, isEmpty } from 'lodash';
import { getStyle } from 'modules/Reconciliation/Report/components/common/utils';
import React, { ReactElement } from 'react';

import { t } from 'utils/t';

import { TransactionStore } from '../stores/transaction.store';

import { Item } from '../types/transaction.type';
import { StyledTableCell, StyledTableHead, StyledTableHeaderCell, StyledTableRow, StyledTotalTableCell } from './ItemsTableStyled';

type Column = {
  title: string;
  accessor: string;
  fn?: (a: any) => any;
};

interface Props {
  withTotal?: boolean;
  totalSum?: number | string;
  totalSumText?: string;
  columns: Column[];
  data: Item[];
}

const useStyles = makeStyles({
  tableWrapper: {
    marginBottom: '48px',
  },
  totalWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalSum: {
    height: '24px',
    color: 'rgb(38, 46, 53)',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    marginBottom: '16px',
    marginTop: '12px',
  },
  couponCode: {
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    color: 'rgba(38, 46, 53, 0.64)',
  },
  refundWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  refundText: {
    color: 'rgb(189, 0, 0)',
    fontSize: '16px',
  },
});

export default function ItemsTable({ columns, data, withTotal = false, totalSum = 0, totalSumText = '' }: Props): ReactElement {
  const c = useStyles();
  const getAlign = (idx: number) => {
    if (idx === 0) return 'left';
    if (idx === columns.length - 1) return 'right';
    return 'center';
  };
  const tx = TransactionStore.transaction.value!;

  const countSubTotalByItems = () => {
    const {
      payment: {
        order: { items },
      },
    } = tx;

    if (isEmpty(items)) return '-';

    return items
      .reduce((acc, item) => {
        const { price, vat_amount } = item;
        acc += price - vat_amount;
        return acc;
      }, 0)
      .toFixed(2);
  };

  const countVAT = () => {
    const {
      payment: {
        order: { items },
      },
    } = tx;

    if (isEmpty(items)) return '-';

    return items
      .reduce((acc, item) => {
        const { vat_amount } = item;
        acc += vat_amount;
        return acc;
      }, 0)
      .toFixed(2);
  };

  return (
    <section className={c.tableWrapper}>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            {columns.map((c, i) => (
              <StyledTableHeaderCell align={getAlign(i)} key={c.title}>
                {c.title}
              </StyledTableHeaderCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((entry, idx) => {
            if (entry.type === 3) {
              return (
                <StyledTableRow key={idx}>
                  <StyledTableCell align='left'>
                    <Box>{entry.title}</Box>
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <Box>
                      {entry.price} {tx.payment.currency}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <Box>0</Box>
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <Box>0</Box>
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    <Box>
                      {entry.price} {tx.payment.currency}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            }
            return (
              <StyledTableRow key={idx}>
                {columns.map((col, colIdx) => {
                  const item = data[idx];
                  const isTitle = col.accessor === 'title';
                  return (
                    <StyledTableCell key={col.accessor + colIdx} align={getAlign(colIdx)} style={getStyle(data, columns, idx, colIdx)}>
                      <Box fontWeight={isTitle ? 700 : 400}>
                        {col.fn !== undefined ? col.fn(get(entry, col.accessor.split('.'))) : get(entry, col.accessor.split('.')) ?? ''}
                        <Typography fontSize={14}>{isTitle && item.subtitle}</Typography>
                      </Box>
                      {item.coupon_code !== undefined && item.coupon_code !== '' && (
                        <>
                          {col.accessor === 'title' && (
                            <Box className={c.couponCode}>
                              {t.staticAsString('reconciliation.transaction.couponCode')}&nbsp;{item.coupon_code}
                            </Box>
                          )}
                          {col.accessor === 'original_price' && (
                            <Box>
                              -{item.discount_amount / 100} {tx.payment.currency}
                            </Box>
                          )}
                        </>
                      )}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
          {withTotal && (
            <>
              <TableRow>
                <StyledTotalTableCell style={{ fontWeight: 'bold', borderTop: '2px solid black' }} />
                <StyledTotalTableCell align='center' style={{ fontWeight: 'bold', borderTop: '2px solid black' }}>
                  {countSubTotalByItems()} {tx.payment.currency}
                </StyledTotalTableCell>
                <StyledTotalTableCell style={{ fontWeight: 'bold', borderTop: '2px solid black' }} />
                <StyledTotalTableCell align='center' style={{ fontWeight: 'bold', borderTop: '2px solid black' }}>
                  {countVAT()} {tx.payment.currency}
                </StyledTotalTableCell>
                <StyledTotalTableCell style={{ fontWeight: 'bold', borderTop: '2px solid black' }} align='right'>
                  {tx.payment.amount} {tx.payment.currency}
                </StyledTotalTableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </section>
  );
}
