import GuestLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Main';
import { inject, observer } from 'mobx-react';
import { FrontErrorPage, NotFoundPage, OrganizerEmailVerification, Terms } from 'modules';
import { organizerVerificationLifeCycle } from 'modules/OrganizerEmailVerification/actions';
import { unavailableServiceContainer } from 'modules/unavailableService';
import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

import { detectIframeLink, history, resize, t } from 'utils';

import { hosts } from 'actions';

import { hostsStore, Session as SessionStore, Toast as ToastStore } from 'stores';

import FilterResetter from '../utils/filterResetter';

import AdminRoutes from './Admin';
import GuestRoutes from './Guest';
import OrganizerRoutes from './Organizer';

type Props = {
  sessionStore: SessionStore;
  toastStore: ToastStore;
};

@withRouter
@inject('sessionStore', 'toastStore')
@observer
class Routes extends React.Component<Props> {
  static defaultProps = {
    sessionStore: null as any,
    toastStore: null as any,
  };

  componentDidMount() {
    const { toastStore, sessionStore } = this.props;

    const urlParts = history.location.pathname.split('/');
    const isIframe = Boolean(urlParts.find((item) => item === 'iframe'));

    // Initialize routes monitoring with subsequent filters reset based on coditions declared within the class.
    // This is the earliest place where we have access to the instance of BrowserHistory used withing ReactRouter.
    new FilterResetter(history);

    if (resize.isLowResolution() && !detectIframeLink()) {
      toastStore.show(t('globalErrors.screenResolution'), null, -1, 'error');
    }

    if (sessionStore.isLogged && hostsStore.isHostsEmpty) {
      hosts.load();
    }
    organizerVerificationLifeCycle.load();
  }

  componentDidCatch(error: Error) {
    console.error(error);
    // history.push(ROUTES.errorPage);
  }

  componentDidUpdate() {
    const { sessionStore } = this.props;

    if (sessionStore.isLogged && hostsStore.isHostsEmpty) {
      hosts.load();
    }
    // if (sessionStore.isLogged && !sessionStore.isOrganizerVerified) {
    //   organizerVerificationLifeCycle.load();
    // }
  }

  routes = () => {
    const { sessionStore } = this.props;

    if (sessionStore.isLogged) {
      if (sessionStore.isAdmin) {
        return AdminRoutes;
      } else {
        return OrganizerRoutes;
      }
    } else {
      return GuestRoutes;
    }
  };

  layout = () => {
    if (this.props.sessionStore.isLogged) return AdminLayout;
    else return GuestLayout;
  };

  render() {
    const Layout = this.layout();
    const routes = this.routes();

    return (
      <Layout>
        <Switch>
          <Route component={OrganizerEmailVerification} path={ROUTES.organizerEmailVerification} />
          <Route key={shortId()} component={Terms} path={ROUTES.cooperationAgreement} />
          <Route path={ROUTES.errorPage} component={FrontErrorPage} />
          <Route path={ROUTES.notFound} component={NotFoundPage} />
          <Route path={ROUTES.serviceUnavailable} component={unavailableServiceContainer} />
          {routes}
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    );
  }
}

export { Routes };
