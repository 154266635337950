import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCES_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_DISTANCES_FOR_INTEGRATIONS } from '../constants';

import { mapDistances } from '../utils';

import { distancesStore } from '../stores';

class Distances {
  @request({ action: GET_DISTANCES_FOR_INTEGRATIONS })
  async loadDistancesRequest(raceId: number): Promise<any> {
    const url = generatePath(RACE_DISTANCES_URL, { raceId });
    return axios.get(url);
  }

  @action({ action: GET_DISTANCES_FOR_INTEGRATIONS, minRequestTime: 500 })
  async loadDistances(id: number): Promise<void> {
    const [status, response] = await this.loadDistancesRequest(id);

    if (status) {
      distancesStore.set(mapDistances(response.data.data));
    }
  }
}

export const distancesService = new Distances();
