import { t } from 'utils';

const base = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
  password: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 6,
      maximum: 255,
      message: () => 'Password must be at least 6 characters long.',
    },
  },
};

const signUpBase = {
  ...base,
  firstname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 255,
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 255,
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: () => 'Please enter a valid phone number.',
    },
    length: {
      minimum: 1,
      maximum: 20,
    },
  },
  country_id: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
  },
};

const termsWithBase = {
  ...signUpBase,
  isTakenCooperation: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.required'),
    },
    inclusion: {
      within: [true],
      message: () => t.staticAsString('shared.errors.required'),
    },
  },
  isTakenDataProcessing: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.required'),
    },
    inclusion: {
      within: [true],
      message: () => t.staticAsString('shared.errors.required'),
    },
  },
};

export { base, termsWithBase };
