import { Transaction } from '../types/transaction.type';

export default function mapBackendToFroend(tx: Transaction): Transaction {
  tx.payment.amount = tx.payment.amount / 100;
  tx.payment.order.items.forEach((item) => {
    item.vat_amount = item.vat_amount / 100;
    item.vat_value = item.vat_value / 100;
    item.original_price = item.original_price / 100;
    item.price = item.price / 100;
    item.vat_excl = item.original_price - item.vat_amount;
  });
  tx.payment.transactions.forEach((transaction) => {
    transaction.amount = transaction.amount / 100;
  });
  return tx;
}
