import * as React from 'react';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import RefundProtectIcon from 'img/refund_protect.png';

import { withErrorClean } from 'hocs';

import { Checkbox } from 'components/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  errors: {
    [K in string]: Array<string>;
  };
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const stepAction = `${action}_${DISTANCE_STEPS.refund_protect}`;

@withErrorClean(stepAction)
class RefundProtect extends React.Component<Props> {
  onChange = (name: string, values: Array<Object> | Object, callback: Function = () => {}, valueForLiveUpdate: Object) => {
    const { onChange, liveUpdate } = this.props;

    liveUpdate(valueForLiveUpdate, true);
    onChange(
      {
        [name]: values,
      },
      '',
      () => {
        callback();
      },
      true,
    );
  };

  onChangeDistance = (name: string, value: any): void => {
    const { formData } = this.props;
    const { distance } = formData;

    this.onChange(
      'distance',
      {
        ...distance,
        [name]: value,
      },
      undefined,
      { [name]: value },
    );
  };

  onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
    const { name, checked } = e.target;

    this.onChangeDistance(name, checked);
  };

  _renderRefundField = (): React.ReactNode => {
    const { refund_protect_enabled } = this.props.formData.distance;

    return (
      <div>
        <Checkbox
          value={refund_protect_enabled}
          name='refund_protect_enabled'
          disabled={false}
          onChange={this.onChangeCheckbox}
          label={t.staticAsString('distances.steps.refundForm.refund_protect')}
          fullWidth
        />
      </div>
    );
  };

  _renderRefundInfo = () => (
    <div className='distance-refund-protect-content'>
      <div className='distance-refund-protect-about'>
        <img alt='refund protect icon' src={RefundProtectIcon} />
        <h2>{t.staticAsString('distances.steps.refundForm.title')}</h2>
        <p>{t.staticAsString('distances.steps.refundForm.about')}</p>
        <p>
          {t.staticAsString('distances.steps.refundForm.price')}
          <span>15% </span>
          {t.staticAsString('distances.steps.refundForm.transaction')}
        </p>
      </div>

      <div className='distance-refund-protect-conditions'>
        <h2>{t.staticAsString('distances.steps.refundForm.cover')}</h2>
        <ul>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.covid')}
            <a target='_blank' href='https://refundable.me/covid/'>
              ({t.staticAsString('distances.steps.refundForm.conditions.covidDetails')})
            </a>
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition1')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition2')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition3')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition4')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition5')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition6')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition7')}
          </li>
          <li>
            <Icon value='refund_check' />
            {t.staticAsString('distances.steps.refundForm.conditions.condition8')}
          </li>
        </ul>

        <p>
          {t.staticAsString('distances.steps.refundForm.conditions.readFull')}
          <a target='_blank' href='https://refundable.me/extended/en'>
            {t.staticAsString('login.termsAndConditions')}
          </a>
        </p>
      </div>
    </div>
  );

  render() {
    return (
      <div className='distance-refund-fields-form'>
        {this._renderRefundInfo()}

        <div className='distance-refund-field'>{this._renderRefundField()}</div>
        {this.props.controls}
      </div>
    );
  }
}

export { RefundProtect };
